import React from 'react';
import { Container } from './styles';

export function PublicityFooter() {
  return (
    <Container>
      Publicidade
    </Container>
  );
}
