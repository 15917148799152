import React from 'react';

import { Container, Publicity } from './styles';

export function DashboardPublicity() {
  return (
    <Container>
      <Publicity>Publicidade</Publicity>

      <Publicity>Publicidade</Publicity>
    </Container>
  );
}
