import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 1.72fr;
  box-sizing: border-box;

  width: 100%;
  /* background-color: #1a1e3a; */
`;

export const Content = styled.div`
`;
